// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../src/pages/About.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-feature-jsx": () => import("./../src/pages/Feature.jsx" /* webpackChunkName: "component---src-pages-feature-jsx" */),
  "component---src-pages-footer-jsx": () => import("./../src/pages/Footer.jsx" /* webpackChunkName: "component---src-pages-footer-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-jsx": () => import("./../src/pages/Intro.jsx" /* webpackChunkName: "component---src-pages-intro-jsx" */)
}

